import { useConfig } from "@geome/react-next"
import { LocatorAppConfig, ShellVOCLocation } from "../types"
import { useMemo } from "react"

export const useStationNumber = (location: ShellVOCLocation | null): string => {
  const { property } = useConfig<LocatorAppConfig["stationNumber"]>("stationNumber", false)
  return useMemo(() => {
    if (!location) return ""
    return location[property]
  }, [location, property])
}
