import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useResetRecoilState } from "@geome/recoil"
import React, { ReactElement } from "react"
import { ShellVOCLocation } from "../../types"
import { CloseIcon } from "../icons/close"
import { selectedLocationIdAtom } from "@geome/recoil/dist/feature/locations/atoms/index"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { useReselectFromHistory } from "@geome/recoil/dist/feature/selection/hooks"
import { FuelSection } from "./fuelSection"
import { Amenities } from "./amenities"
import { useSubscribeToUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { useSelectedLocation } from "@geome/recoil/dist/feature/locations/hooks"
import { EvChargingSection } from "./evCharging"
import { useStationNumber } from "../../hooks/useStationNumber"
import { useSelectStation } from "../../hooks/useSelectStation"

export const InfoWindow = (): ReactElement | null => {
  const { translate } = useI18n()
  const selectedLocation = useSelectedLocation<ShellVOCLocation>()
  const selectStation = useSelectStation()

  const closeInfoWindow = useResetRecoilState(selectedLocationIdAtom)
  const returnToSearch = useReselectFromHistory({ type: "google_place" })
  const closeAndReturnToSearch = (): void => {
    closeInfoWindow()
    returnToSearch()
  }

  useSubscribeToUserInteraction(
    "*,-*:location:select,-*:completion:select,-*:telephone-link:*,-*:website-link:*",
    closeInfoWindow
  )

  const code = useStationNumber(selectedLocation)

  if (!selectedLocation) return null

  return (
    <BlockScopingContextProvider block="info-window">
      <div className="info-window">
        <div className="info-window__title">
          <h1 className="info-window__title-name">{selectedLocation.name}</h1>
          <ButtonWithIcon
            className="info-window__close-button"
            text={translate("info_window.close") as string}
            textPosition="tooltip"
            icon={<CloseIcon />}
            onClick={closeAndReturnToSearch}
            name="close-button"
          />
        </div>
        <div className="info-window__address">{selectedLocation.formatted_address}</div>
        <div className="info-window__site-code">{translate("site_id_code", { code })}</div>

        <button className="info-window__button" onClick={() => selectStation(code)}>
          {translate("info_window.use_this_station")}
        </button>

        <div>
          <FuelSection location={selectedLocation} />
          <EvChargingSection location={selectedLocation} />
          <Amenities location={selectedLocation} />
        </div>
      </div>
    </BlockScopingContextProvider>
  )
}
