import { SectionWithTitle } from "@geome/react-components-next/lib/components/sectionWithTitle"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import React, { Fragment, ReactElement } from "react"
import { ShellConnectorType, ShellVOCLocation } from "../../types"
import { useIconFor } from "@geome/react-components-next/lib/components/properties/hooks/useIconFor"
import icons from "@geome/shell-locator-icons"
import { EVLightningBoltIcon } from "../icons/evLightningBolt"

type EvChargingSectionProps = {
  location: ShellVOCLocation
  openByDefault?: boolean
}

export const EvChargingSection = ({
  location: { ev_charging, country_code },
  openByDefault = false,
}: EvChargingSectionProps): ReactElement | null => {
  const { translate } = useI18n()

  if (!ev_charging) return null

  const sectionTokenPath = "info_window.sections.ev_charging"
  const availableConnectors = Object.values(ev_charging.connector_data)
  const hasSiteAvailability = ev_charging.available_charging_points !== undefined

  return (
    <SectionWithTitle
      className="info-window__section-with-title ev-charging"
      sectionTokenPath={sectionTokenPath}
      renderIcon={EVLightningBoltIcon}
      id="ev_charging"
      collapsible={{ openByDefault }}
    >
      <div className="ev-charging__content">
        <label className="ev-charging__label" htmlFor="number-of-chargers">
          {translate(`${sectionTokenPath}.number_of_charging_points`)}
        </label>
        <span id="number-of-chargers" className="ev-charging__number-of-chargers">
          {hasSiteAvailability &&
            translate(`${sectionTokenPath}.site_availability`, {
              available: ev_charging.available_charging_points,
              count: ev_charging.charging_points,
            })}
          {!hasSiteAvailability &&
            translate(`${sectionTokenPath}.site_count`, {
              count: ev_charging.charging_points,
            })}
        </span>
        <label className="ev-charging__label" htmlFor="connector-types">
          {translate(`${sectionTokenPath}.connector_types`)}
        </label>
        <div role="list" id="connector-types" className="ev-charging__connector-types">
          {availableConnectors.map(({ available, max_power, type, total }) => {
            const hasConnectorAvailability = available !== undefined

            return (
              <Fragment key={type}>
                <div
                  className={`ev-charging__connector ${
                    hasConnectorAvailability ? "" : "ev-charging__connector--name-only"
                  }`}
                >
                  <div role="presentation" className="ev-charging__connector-icon">
                    <EvChargingIcon type={type} countryCode={country_code} />
                  </div>
                  <div className="ev-charging__connector-name">
                    {translate(`${sectionTokenPath}.connector_name_and_power`, {
                      connectorName: translate(`${sectionTokenPath}.connectors.${type}`) as string,
                      maxPower: max_power,
                    })}
                  </div>
                  {hasConnectorAvailability && (
                    <div className="ev-charging__connector-availability">
                      {translate(`${sectionTokenPath}.connector_availability`, {
                        available,
                        count: total,
                      })}
                    </div>
                  )}
                </div>
              </Fragment>
            )
          })}
        </div>
      </div>
    </SectionWithTitle>
  )
}

type EvChargingIconProps = {
  type: ShellConnectorType
  countryCode: string
}

const EvChargingIcon = ({ type, countryCode }: EvChargingIconProps): ReactElement | null => {
  const iconKey = `ev-connectors.${type}`
  const iconSrc = useIconFor(iconKey, countryCode, icons)

  if (!iconSrc) return null

  return <img aria-hidden="true" src={iconSrc} className="ev-charging__connector-icon" alt="" />
}
