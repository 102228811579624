import React, { ReactElement } from "react"
import { ShellVOCLocation } from "../../types"
import { SectionWithTitle } from "@geome/react-components-next/lib/components/sectionWithTitle"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { PumpIcon } from "../icons/pump"

type FuelSectionProps = {
  location: ShellVOCLocation
}

export const FuelSection = ({ location }: FuelSectionProps): ReactElement => {
  const countryCode = location.country_code
  const { translate } = useI18n()

  return (
    <>
      {location.fuels && location.fuels.length > 0 && (
        <SectionWithTitle
          className="info-window__section-with-title"
          sectionTokenPath="info_window.sections.fuels"
          renderIcon={PumpIcon}
          id="fuels"
          collapsible
        >
          <ul className="info-window__fuels-list">
            {location.fuels.map((f) => (
              <li key={f}>
                {translate(`info_window.sections.fuels.fuel_local_names.${f}`, { countryCode })}
              </li>
            ))}
          </ul>
        </SectionWithTitle>
      )}
    </>
  )
}
