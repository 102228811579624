import {
  useIsShowingMapClusters,
  useLocations,
  useSelectedLocation,
} from "@geome/recoil/dist/feature/locations/hooks"
import { useMobileMapListState, useModalState } from "@geome/recoil/dist/feature/ui/hooks"
import { ShellVOCLocation, ShellVOCModal } from "../../../types"

export const useViewSwitcher = (): {
  showList: boolean
  showFilters: boolean
  showInfoWindow: boolean
} => {
  const isShowingClusters = useIsShowingMapClusters({ resource: "locations" })
  const listLocations = useLocations({ resource: "locations", endpoint: "nearest_to" })
  const { currentModal } = useModalState<ShellVOCModal>()
  const selectedLocation = useSelectedLocation<ShellVOCLocation>()

  const { mobileView } = useMobileMapListState()

  const showInfoWindow = selectedLocation !== null
  const showList = mobileView === "list" && !isShowingClusters && listLocations.length > 0
  const showFilters = currentModal?.name === "Filters"

  return {
    showList,
    showFilters,
    showInfoWindow,
  }
}
