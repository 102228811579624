import { CustomProperties } from "@geome/react-next"

export const cssProps = {
  "--primary-colour": "#DD1D21",
  "--shell-yellow-colour": "#FBCE07",
  "--shell-grey-colour": "#4A4A4A",
  "--shell-medium-grey-colour": "#D9D9D9",
  "--shell-natural-grey-colour": "#887D7D",
  "--shell-light-grey-colour": "#F7F7F7",
  "--shell-dark-red-colour": "#b1171a",
  "--tertiary-colour": "var(--shell-grey-colour)",
  "--search-input__text-colour": "var(--shell-grey-colour)",
  "--search-input__margin": "15px",
  "--tab-text-colour": "var(--shell-grey-colour)",
  "--text-dark-colour": "#4A4A4A",
} as const satisfies CustomProperties
