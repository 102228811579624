import React, { ReactElement, useId } from "react"

export const PumpIcon = (): ReactElement => {
  const idA = useId()
  const idB = useId()
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none">
      <path d="M0 20.2571h13.8947v1.0286H0v-1.0286z" fill="#4a4a4a" />
      <mask
        id={idA}
        maskUnits="userSpaceOnUse"
        x="1"
        y="0"
        width="12"
        height="22"
        mask-type="alpha"
      >
        <path d="M1.1582 21.2857h11.579V.714294H1.1582V21.2857z" fill="#fff" />
      </mask>
      <g mask={`url(#${idA})`}>
        <path d="M1.1582 21.2857h11.579V.714294H1.1582V21.2857z" stroke="#4a4a4a" strokeWidth="2" />
      </g>
      <g stroke="#4a4a4a">
        <path d="M18.6621 15.283h2.2625v-3.5177h-2.2625v3.5177z" />
        <path
          d="M18.6617 15.283v3.0137c0 .9864-.213 1.4883-1.2447 1.4883s-1.5562-.5019-1.5562-1.4883V5.73781c0-.70662-.3902-1.50685-1.1301-1.50685h-1.6975m5.6285 8.03724v-1.0049c0-1.41326 2.6018-3.51566 2.6018-3.51566l-2.6018 4.52056z"
          strokeLinecap="square"
        />
      </g>
      <mask id={idB} maskUnits="userSpaceOnUse" x="3" y="2" width="8" height="5" mask-type="alpha">
        <path d="M3.47363 6.88571H10.421V2.77142H3.47363V6.88571Z" fill="#fff" />
      </mask>
      <g mask={`url(#${idB})`}>
        <path
          d="M3.47363 6.88571H10.421V2.77142H3.47363V6.88571Z"
          stroke="#4a4a4a"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}
