import React, { ReactElement, useState } from "react"
import { PropertiesSection } from "@geome/react-components-next/lib/components/properties"
import { PropertiesIconList } from "@geome/react-components-next/lib/components/properties/iconList"
import { useEnabledProperties } from "@geome/react-components-next/lib/components/properties/hooks/useEnabledProperties"
import { ShellVOCLocation } from "../../types"
import { TeacupIcon } from "../icons/teacup"
import { useConfig } from "@geome/react-next"
import icons from "@geome/shell-locator-icons"

type AmenitiesProps = {
  location: ShellVOCLocation
}

export const Amenities = ({ location }: AmenitiesProps): ReactElement => {
  const { enabled, maxStandaloneIcons } = useConfig<{
    enabled: string[]
    maxStandaloneIcons: number
  }>(`info_window.sections.amenities`, false)
  const [isExpanded, setIsExpanded] = useState(false)
  const enabledPropertiesList = useEnabledProperties(location, enabled)
  return (
    <>
      <PropertiesSection
        configPath="info_window."
        sectionName="amenities"
        countryCode={location.country_code}
        renderTitleIcon={() => <TeacupIcon />}
        location={location}
        renderNullIfEmpty
        icons={icons}
        sectionProps={{
          collapsible: {
            onCollapseOrExpand: setIsExpanded,
          },
        }}
      />
      {!isExpanded && (
        <PropertiesIconList
          countryCode={location.country_code}
          properties={enabledPropertiesList}
          icons={icons}
          maxIcons={maxStandaloneIcons}
        />
      )}
    </>
  )
}
