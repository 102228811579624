import { Locale } from "@geome/types"
import { LocatorAppConfig } from "../../types"

type QueryParams = { [key: string]: string | undefined }

export const addQueryAreaAndLocale = (queryParams: QueryParams): Partial<LocatorAppConfig> => {
  const { country, language, ...otherParams } = queryParams

  return {
    ...otherParams,
    ...addArea(country),
    ...addLocale(language),
  }
}

const addArea = (country: string | undefined): Partial<LocatorAppConfig> => {
  const countryCode = country ? (mapCountryCode(country).toUpperCase() as Uppercase<string>) : null
  if (!countryCode) return {}

  return { area: countryCode }
}

const addLocale = (language: string | undefined): Partial<LocatorAppConfig> => {
  const locale = language ? mapLanguage(language) : null
  if (!locale) return {}

  return { locale }
}

const mapCountryCode = (code: string): string => {
  switch (code) {
    case "UK":
      return "GB"
    default:
      return code
  }
}

const mapLanguage = (code: string): Locale => {
  switch (code) {
    case "en_UK":
      return "en_GB"
    default:
      return code as Locale
  }
}
