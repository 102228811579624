import React, { ReactElement } from "react"
import { RecoilState, useRecoilCallback, useRecoilValue } from "@geome/recoil"
import { SearchCompletion } from "@geome/react-components-next/lib/components/search/completion"
import { HistoryIcon } from "../icons/history"
import { SelectionIntent } from "@geome/types"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { usePublishUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { recentSearchesAtom } from "@geome/recoil/dist/feature/search/atoms"

type RecentSearchesProps = {
  onSelect: () => void
  selectionIntentAtom: RecoilState<SelectionIntent | null>
}

export const RecentSearches = ({
  onSelect,
  selectionIntentAtom,
}: RecentSearchesProps): ReactElement | null => {
  const { translate } = useI18n()
  const recentSearches = useRecoilValue(recentSearchesAtom)
  const [completionClicked] = usePublishUserInteraction<{ completion: SelectionIntent }>(
    "completion",
    "select"
  )

  const onClick = useRecoilCallback(
    ({ set }) =>
      (completion: SelectionIntent) => {
        set(selectionIntentAtom, null)
        setTimeout(() => set(selectionIntentAtom, completion), 10)
        onSelect()
        completionClicked({ completion })
      },
    []
  )

  const onClear = useRecoilCallback(
    ({ set }) =>
      () =>
        set(recentSearchesAtom, []),
    []
  )

  if (recentSearches.length === 0) return null

  return (
    <div className="recent-searches">
      <div className="recent-searches__header">
        <span>{translate("search.completions.recent")}</span>
        <div role="button" tabIndex={0} className="recent-searches__btn" onClick={() => onClear()}>
          {translate("search.completions.clear_all_recent")}
        </div>
      </div>
      {recentSearches.map((rs) => (
        <SearchCompletion key={getId(rs)} onClick={() => onClick(rs)}>
          <HistoryIcon size={20} /> {rs.description}
        </SearchCompletion>
      ))}
    </div>
  )
}

const getId = (rs: SelectionIntent): string => rs["id"] ?? rs["place_id"]
