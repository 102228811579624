import React, { ReactElement } from "react"
import { LocationsList as LocationsListBase } from "@geome/react-components-next/lib/components/locationsList"
import { ShellVOCLocation } from "../../types"
import { LocationsListItem } from "@geome/react-components-next/lib/components/locationsList/item"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { ResourceEndpoint } from "@geome/types"
import { useI18n } from "@geome/react-next"
import { useStationNumber } from "../../hooks/useStationNumber"

export const LocationsList = ({
  endpoint = "nearest_to",
}: {
  endpoint?: ResourceEndpoint
}): ReactElement => (
  <BlockScopingContextProvider block="locations-list">
    <LocationsListBase endpoint={endpoint} resource="locations">
      {(location: ShellVOCLocation) => (
        <LocationsListItem
          key={location.id}
          resource="locations"
          location={location}
          renderContent={() => <LocationsListItemContent location={location} />}
        />
      )}
    </LocationsListBase>
  </BlockScopingContextProvider>
)

const LocationsListItemContent = ({ location }: { location: ShellVOCLocation }): ReactElement => {
  const { translate } = useI18n()
  const code = useStationNumber(location)

  return (
    <>
      <div className="locations-list__name">{location.name}</div>
      <div className="locations-list__address">{location.formatted_address}</div>
      <div className="locations-list__code">{translate("site_id_code", { code })}</div>
    </>
  )
}
