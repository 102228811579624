import React, { ReactElement, useRef } from "react"
import { useBEM } from "@geome/react-next/lib/hooks/useBEM"
import { useIsMobile } from "../../../hooks/useIsMobile"
import { useSidebarAdjustedUIPadding } from "../../../hooks/useSidebarAdjustedUIPadding"
import useResizeObserver from "use-resize-observer"
import { Search } from "../../search"
import { LocationsList } from "../../locationsList"
import { SidebarTabs } from "./tabs"
import { useViewSwitcher } from "./useViewSwitcher"
import { FiltersButton } from "../../filters/button"
import { Filters } from "../../filters"
import { InfoWindow } from "../../infoWindow"

export const AppSideBar = (): ReactElement => {
  const isMobile = useIsMobile()

  const { showList, showFilters, showInfoWindow } = useViewSwitcher()

  const sidebarRef = useRef<HTMLDivElement>(null)
  const sidebarContentRef = useRef<HTMLDivElement | null>(null)

  useResizeObserver({
    ref: sidebarContentRef,
    onResize: (size) => {
      if (!sidebarContentRef.current) return
      sidebarContentRef.current.style.setProperty("--sidebar-width", `${size.width}px`)
    },
  })

  useSidebarAdjustedUIPadding({ sidebarRef, sidebarContentRef })

  // Can replace `has-content` with :has(.app__sidebar-content:not(:empty) once that's a thing
  const className = useBEM({
    block: "app",
    element: "sidebar",
    modifiers: {
      mobile: isMobile,
      "has-content": showList || showFilters || showInfoWindow,
    },
  })

  return (
    <div className={className} ref={sidebarRef}>
      <Search />

      <div className="app__sidebar-nav">
        <SidebarTabs />
        <div className="app__sidebar-nav-buttons">
          <FiltersButton />
        </div>
      </div>

      <div className="app__sidebar-content" ref={sidebarContentRef}>
        {showList && <LocationsList />}
        {showFilters && <Filters />}
        {showInfoWindow && <InfoWindow />}
      </div>
    </div>
  )
}
