import { GoogleMap } from "@geome/react-components-next/lib/components/map/google"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"

import React, { ReactElement } from "react"
import { Clusters } from "./clusters"
import { Locations } from "./locations"
import { MapOptions } from "./mapOptions"
import { LocationTooltip } from "./locationTooltip"

export const Map = (): ReactElement => (
  <BlockScopingContextProvider block="map">
    <GoogleMap>
      <Locations />
      <LocationTooltip />
      <Clusters />
      <MapOptions />
    </GoogleMap>
  </BlockScopingContextProvider>
)
