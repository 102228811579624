import React from "react"
import { ConfigContextProvider } from "@geome/react-next/lib/context/config"
import { GoogleMapsLoaderUsingConfig } from "@geome/react-components-next/lib/components/map/google/loader"
import { config } from "../../config"
import { Resources } from "../resources"
import { I18nContextProvider } from "@geome/react-next/lib/context/i18nContext/provider"
import { AppSideBar } from "./sidebar"
import { initialiseFilterState } from "@geome/recoil/dist/feature/filters/initialisers"
import { initialiseDirectionsOptions } from "@geome/recoil/dist/feature/directions/initialisers"
import { initialiseResourcesState } from "@geome/recoil/dist/feature/resources/initialisers"
import { AppConfig } from "@geome/types"
import { RecoilMultiInitRoot } from "@geome/recoil"
import { GeospatialApiRequesterContextProvider } from "@geome/geospatial-api-requester"
import { CSSCustomPropertyContextProvider } from "@geome/react-next/lib/context/cssCustomProperty"
import DistanceFormatHelper from "@geome/intl/lib/formatHelper/distance"
import { Map } from "../map"
import { UserInteractionContextProvider } from "@geome/react-next/lib/context/userInteraction"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { setSelectionIntentFromURL } from "@geome/recoil/dist/feature/selection/initalisers/fromURL"
import { LocatorAppConfig } from "../../types"
import { cssProps } from "./cssProps"
import { addQueryAreaAndLocale } from "./addQueryAreaAndLocale"

const App = (): JSX.Element => (
  <ConfigContextProvider
    value={config as LocatorAppConfig}
    transformQueryParams={addQueryAreaAndLocale}
  >
    {(finalConfig: AppConfig) => (
      <RecoilMultiInitRoot
        initialisers={[
          initialiseFilterState(finalConfig),
          initialiseResourcesState(finalConfig),
          initialiseDirectionsOptions(finalConfig),
          setSelectionIntentFromURL,
        ]}
      >
        <UserInteractionContextProvider>
          <GeospatialApiRequesterContextProvider>
            <I18nContextProvider
              intlData={finalConfig.intlData}
              locale={finalConfig.locale}
              intlExplorer={finalConfig.intlExplorer}
              mode={process.env.NODE_ENV !== "prod" ? "development" : "production"}
              formatHelpers={[DistanceFormatHelper]}
            >
              <GoogleMapsLoaderUsingConfig>
                <CSSCustomPropertyContextProvider properties={cssProps}>
                  <BlockScopingContextProvider block="app">
                    <div className="app">
                      <AppSideBar />
                      <Map />
                      <Resources />
                    </div>
                  </BlockScopingContextProvider>
                </CSSCustomPropertyContextProvider>
              </GoogleMapsLoaderUsingConfig>
            </I18nContextProvider>
          </GeospatialApiRequesterContextProvider>
        </UserInteractionContextProvider>
      </RecoilMultiInitRoot>
    )}
  </ConfigContextProvider>
)

export { App }
