import React, { ReactElement } from "react"
import { ShellLocationCompletion, ShellVOCLocation } from "../../types"
import { useShellVOCIcon } from "@geome/markers/lib/client/shell/voc/useIcon"

type CompletionLocationIconProps = {
  location: ShellLocationCompletion
}

export const CompletionLocationIcon = ({ location }: CompletionLocationIconProps): ReactElement => {
  const { value, id, site_category, offers_ev_charging, ship_to, esi_code } = location

  const fakeLocation: ShellVOCLocation = {
    formatted_address: value,
    country_code: "",
    id,
    amenities: [],
    site_category,
    inactive: false,
    offers_ev_charging,
    lat: 0,
    lng: 0,
    name: value,
    esi_code,
    ship_to,
  }

  const icon = useShellVOCIcon(fakeLocation)

  return <img aria-hidden="true" src={icon.url} height={17} width={17} />
}
