import { useLocations } from "@geome/recoil/dist/feature/locations/hooks"
import React, { ReactElement } from "react"
import { ShellVOCMarker } from "@geome/markers"
import { ShellVOCLocation } from "../../types"

export const Locations = (): ReactElement[] => {
  const locations = useLocations<ShellVOCLocation>({
    resource: "locations",
    endpoint: "within_bounds",
  })

  return locations.map((l) => <ShellVOCMarker key={l.id} location={l} />)
}
