import { TabButton } from "@geome/react-components-next/lib/components/buttons/tab"
import { useMobileMapListState } from "@geome/recoil/dist/feature/ui/hooks"
import React, { ReactElement } from "react"
import { ListIcon } from "../../icons/list"
import { MapIcon } from "../../icons/map"
import { useIsShowingMapClusters } from "@geome/recoil/dist/feature/locations/hooks"

export const SidebarTabs = (): ReactElement | null => {
  const { mobileView, setMobileView } = useMobileMapListState()
  const isShowingClusters = useIsShowingMapClusters({ resource: "locations" })

  if (isShowingClusters) return null

  return (
    <>
      <TabButton
        name="map"
        renderIcon={() => <MapIcon />}
        active={mobileView === "map"}
        configPath="mobile_nav."
        onClick={() => setMobileView("map")}
      />
      <TabButton
        name="list"
        renderIcon={() => <ListIcon />}
        active={mobileView === "list"}
        configPath="mobile_nav."
        onClick={() => setMobileView("list")}
      />
    </>
  )
}
